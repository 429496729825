* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.horizontal-row {
	display: flex;
	justify-content: center;
	margin-top: 70px;
}

.manage-classes {
	background-color: #3e8e41;
	border-radius: 4px;
	outline-offset: 3px;
	padding: 10px 16px;
	color: white;
	font-size: 16px;
	margin-left: 15px;
	border: none;
	cursor: pointer;
}

.dropbtn {
	background-color: #67848e;
	color: white;
	padding: 12px 16px;
	font-size: 18px;
	min-width: 450px;
	border: none;
	cursor: pointer;
	letter-spacing: 3px;
	word-spacing: 4px;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 450px;
	max-height: 325px;
	overflow: scroll;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-content div {
	color: black;
	/* text-transform: capitalize; */
	font-size: 16px;
	padding: 8px 16px;
}

.dropdown-content div:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown:hover .dropbtn {
	background-color: #67848e;
}

.add-subasset-modal-div {
	position: fixed;
	/* display: flex;
	justify-content: center;
	align-items: center; */
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: 1;
	background-color: rgba(15, 14, 14, 0.4);
}

.add-subasset-modal {
	position: relative;
	top: 30%;
	left: 30%;
	width: 30%;
	height: 280px;
	background-color: white;
	padding: 30px;
	border-radius: 5px;
}

.sub-asset-label {
	display: block;
	width: 100%;
	font-size: 20px;
	border: none;
	text-transform: capitalize;
}

.sub-asset-input {
	display: block;
	font-size: 20px;
	padding: 6px 8px;
	width: 100%;
	border: 1px solid gray;
	font-size: 15px;
	margin-bottom: 4px;
	color: gray;
	border-radius: 3px;
}
.sub-asset-input:hover {
	outline: 4px solid rgb(219, 245, 252);
	border: 1px solid rgb(121, 200, 231);
}

.add-button-subasset {
	display: block;
	width: 100%;
	border: none;
	padding: 5px 0px;
	font-size: 18px;
	color: white;
	border-radius: 3px;
	text-transform: capitalize;
}
.add {
	background-color: #67848e;
	margin-top: 35px;
}
.cancel {
	background-color: #924040;
	margin-top: 10px;
}

.dropbtn.manage-mails {
	letter-spacing: 3px;
	word-spacing: 4px;
	min-width: 565px;
}

.dropdown-content.manage-mails {
	min-width: 565px;
}

.customize-mail-button {
	border: none;
	font-size: 13px;
	background-color: #3e8e41;
	border-radius: 3px;
	text-transform: capitalize;
	color: white;
	padding: 4px 8px;
}

.display-mail {
	width: 80%;
	margin: auto;
	margin-top: 50px;
}

.display-mail iframe {
	width: 100%;
	height: 300px;
}

.preview {
	padding: 15px 20px;
	background-color: rgb(228, 245, 250);
}

.display-mail-html {
	width: 80%;
	margin: auto;
}
.display-mail-html label {
	color: #6c757d;
	font-weight: bold;
	font-size: 16px;
}
.previewbtn {
	background-color: #858585;
}
.cancelbtn {
	background-color: #924040;
}
.restorebtn {
	background-color: #67748f;
}
.texteditor-div {
	margin-top: 35px;
}

.tripple-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.tripple-buttons div {
	width: 200px;
	display: flex;
	justify-content: space-between;
}
.list-element {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ql-snow .ql-tooltip {
	display: none !important;
}

.note-span {
	padding: 4px 6px;
	margin-left: 20px;
	color: rgb(151, 9, 9);
	font-size: 13px;
}
.note-span b {
	font-size: 13px;
	text-transform: capitalize;
}

/* tool tip */

.custom-tooltip {
	margin-left: 20px;
	background-color: lightgray;
	padding: 1px 10px;
	border-radius: 2px;
	position: relative;
	display: inline-block;
}
.hide-me {
	display: none;
}

.custom-tooltip .tooltiptext-block {
	visibility: hidden;
	width: 130px;
	min-height: 125px;
	background-color: #6c757d;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	top: -50px;
	left: 110%;
}

.custom-tooltip .tooltiptext-block::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #6c757d transparent transparent;
}

.custom-tooltip:hover .tooltiptext-block {
	visibility: visible;
}
