#page-wrap{
  margin-left: 90px;
}

#content-screen{
  margin-left: 100px;
  font-size: 15px;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 400px !important;
  height: 500px;
  padding: 40px 40px;
  /* background-image: url(../src/images/s3.jpg); */
  background-repeat: no-repeat;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  /* -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; */
}

#navbar-adjust {
  padding-left: 96px;
  color: #f4fbe3;
  /* text-shadow: 2px 2px #f4fbe3; */
  font-weight: bold;
  font-size: 20px;
}

.profile-details {
  margin-top: 10px;
  color: #f4fbe3;
  border-radius: 8px;
  height: 40px;
}

h6{
  padding-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

#h6-color{
  color: white;
}

#logout-icon{
  color: #fafafa;
  padding-left: 25px;
  font-size: 30px;
}

#hr-shadow{
  border:none;
	height: 50px;
	margin-top: 0;
	box-shadow: 0 20px 10px -20px gray;
  margin: -50px auto 10px;
  margin-bottom: 25px;
}

#user_role{
  padding-left: 10px;
}

#header-style{
  width: 100%;
  height: 75px;
  z-index: 9999;
  background-color: #34a0f9;
  position: fixed;
  top: 0;
  background-image: url(../src/images/s10.jpg);
  /* opacity: 0.8; */
}

#logo{
  width: 55px;
  height: 55px;
}

/* #username{
  color: whitesmoke;
}

#password{
  color: whitesmoke;
}

#forget-pass{
  color: whitesmoke;
} */

@media screen and (max-width: 768px) {
  .nav-view{
    width: 63em;
  }
}