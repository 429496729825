#input-pass-filter {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	border: 1px solid #dee2e6;
	color: #000;
	width: 250px;
	height: 30px;
	padding-left: 10px;
}

#input-pass-filter {
	outline: none;
	border: 1px solid #5aa7ce;
	color: #000;
}

.search-icon {
	font-size: larger;
	padding-left: 1%;
	color: #184994;
}
