.sidenav {
    height: 100%;
    width: 70px;
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 0;
    /* background-color: #210070; */
    background-color: #f5f3f3;
    /* -webkit-animation: fadein 2s ease-in alternate infinite;
    -moz-animation: fadein 2s ease-in alternate infinite;
    animation: fadein 2s ease-in alternate infinite; */
  }
  
  .sidenav a {
    padding: 15px 8px 6px 8px;
    text-decoration: none;
    font-size: 15px;
    color: #818181;
    display: block;
  }

  .sidenav a:hover {
    background-color: #acabab;
  }

  /* .sidenav a:active {
    background-color: #a09a9a;
  } */

  .is-active {
    background-color: #fff;
  }
  
  .main {
    margin-left: 160px; /* Same as the width of the sidenav */
    font-size: 28px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  .avatar-name {
    font-family: "Sofia", sans-serif;
    text-shadow: 2px 2px 5px orange;
  }

  #home-icon{
    color: #007bfff0;
    padding-left: 15px;
    font-size: 25px;
  }

  #adjust-sidebar{
    padding-top: 80px;
  }

/* @-webkit-keyframes fadein {
    from { opacity: 0.3; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0.3; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0.3; }
    to { opacity: 1; }
} */

#more-btn{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}