/*! 
 * Base CSS
 * Copyright 2012,2013 Lu Wang <coolwanglu@gmail.com> 
 * https://github.com/coolwanglu/pdf2htmlEX/blob/master/share/LICENSE
 */#sidebar{
    left:0;
    bottom:0;
    width:300px;
    padding:0;
    margin:0;
    overflow:auto
   }
   
   #page-container{
       left:0;
       margin:0;
       padding:0;
       border:0
   }
   
   @media screen{
       #sidebar.opened+#page-container{
           left:250px
       }
       
       #page-container{
           bottom:0;
           right:0;
           overflow:auto
       }
       
       .loading-indicator{
           display:none
       }
       
       .loading-indicator.active{
           display:block;
           position:absolute;
           width:64px;
           height:64px;
           top:50%;
           left:50%;
           margin-top:-32px;
           margin-left:-32px
       }
       
       .loading-indicator img{
           position:absolute;
           top:0;
           left:0;
           bottom:0;
           right:0
       }
   }
   /*dont format to remove below code while */
   /* .nav-view, .sidenav{
    display: none;
   }  */
   
   @media print{
       @page{
           margin:0
       }
       
       html{
           margin:0
       }
       
       body{
           margin:0;
           -webkit-print-color-adjust:exact
       }
       
       #sidebar{
           display:none
       }
       
       #page-container{
           width:auto;
           height:auto;
           overflow:visible;
           background-color:transparent
       }
       
       .d{
           display:none
       }
   }
   
   .pf{
       position:relative;
       background-color:white;
       overflow:hidden;
       margin:0;
       border:0
   }
   
   .pc{
       position:absolute;
       border:0;
       padding:0;
       margin:0;
       top:0;
       left:0;
       width:100%;
       height:100%;
       overflow:hidden;
       display:block;
       transform-origin:0 0;
       -ms-transform-origin:0 0;
       -webkit-transform-origin:0 0
   }
   
   .pc.opened{
       display:block
   }
   
   .bf{
       position:absolute;
       border:0;
       margin:0;
       top:0;
       bottom:0;
       width:100%;
       height:100%;
       -ms-user-select:none;
       -moz-user-select:none;
       -webkit-user-select:none;
       user-select:none
   }
   
   .bi{
       position:absolute;
       border:0;
       margin:0;
       -ms-user-select:none;
       -moz-user-select:none;
       -webkit-user-select:none;
       user-select:none
   }
   
   @media print{
       .pf{
           margin:0;
           box-shadow:none;
           page-break-after:always;
           page-break-inside:avoid
       }
       
       @-moz-document url-prefix(){
           .pf{
               overflow:visible;
               border:1px solid #fff
           }
           
           .pc{
               overflow:visible
           }}}
           
           .c{
               position:absolute;
               border:0;
               padding:0;
               margin:0;
               overflow:hidden;
               display:block
           }
           
           .t{
               position:absolute;
               white-space:pre;
               font-size:1px;
               transform-origin:0 100%;
               -ms-transform-origin:0 100%;
               -webkit-transform-origin:0 100%;
               unicode-bidi:bidi-override;
               -moz-font-feature-settings:"liga" 0
           }
           
           .t:after{
               content:''
           }
           
           .t:before{
               content:'';
               display:inline-block
           }
           
           .t span{
               position:relative;
               unicode-bidi:bidi-override
           }
           
           ._{
               display:inline-block;
               color:transparent;
               z-index:-1
           }
           
           ::selection{
               background:rgba(127,255,255,0.4)}
               ::-moz-selection{
                   background:rgba(127,255,255,0.4)
               }
               
               .pi{
                   display:none
               }
               
               .d{
                   position:absolute;
                   transform-origin:0 100%;
                   -ms-transform-origin:0 100%;
                   -webkit-transform-origin:0 100%
               }
               
               .it{
                   border:0;
                   background-color:rgba(255,255,255,0.0)
               }
               
               .ir:hover{
                   cursor:pointer
               }