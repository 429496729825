#input-pass{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
     border-radius: 20px;
     border: 1px solid #dee2e6;
     color: #000;
     width: 250px;
     height: 30px;
     padding-left: 10px;
    }
    
#input-pass {
     outline: none;
     border: 1px solid #2d9fd9;
     color: #000;
}



.search-icon {
    font-size: larger;
    padding-left: 1%;
    color: #0065ff;
}

#modal-header{
    text-align: center;
    color: #1890ff;
}

#modal-content{
    text-align: center;
    color: red;
}

#modal-footer{
    padding-top: 5px;
}

#btn-yes-style{
    color: red;
}

#btn-no-style{
    color: Red;
}