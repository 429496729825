#home-image{
    margin-top: 95px;
    margin-left: 280px;
    width: 450px;
    height: 450px;
  }

#Under-Construction-Status{
    font-size: larger;
    padding-left: 340px;
}