.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 90%;
  z-index: 100;
  overflow: hidden;
}

.header {
  background:#210070;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
  
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: center;

}

.actions button {
  margin-left: 10px;
}

@media (min-width: 868px) {
  .modal {
    left: calc(50% - 30rem);
    width: 60rem;
    height: 40rem;
    overflow: auto;
  }
}
