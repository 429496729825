#dvwm-header {
	margin-top: 110px;
	width: 100%;
	background: #f5f3f3;
	font-family: sans-serif;
}

#dvwm-select-model {
	width: 50%;
	margin: auto;
}

#dvwm-select-threshold {
	width: 50%;
	margin: auto;
}

#threshold-main-container {
	padding: 0px 280px 0px 400px;
}

#dvwm-select-threshold-error {
	/* width: 50%;
	margin: auto; */
	padding: 0px 0px 0px 0px;
}

#threshold-error {
	font-size: 9px;
	color: red
}

#dvwm-accordian-toggle {
	border: none;
	border-radius: 20px;
	padding: 2px 18px;
	font-size: 15px;
	color: white;
	background-color: rgb(130, 179, 180);
}

#dvwm-header-SAC {
	border-radius: 20px;
	margin: 20px 0px;
	padding: 6px;
	text-align: center;
	background: #d8e2dc;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 2px;
	display: block;
}

#dvwm-ag-grid {
	width: 60%;
	height: 300px;
	margin: auto;
}

#dvwm-header-securities {
	border-radius: 20px;
	margin: 20px 0px;
	padding: 6px;
	text-align: center;
	background: #d8e2dc;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 2px;
	display: block;
}

.dvwm-accordian-text-sec {
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: bold;
}

.dvwm-accordian-text-sec span {
	margin-left: 10px;
	margin-right: 20px;
}
