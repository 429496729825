#style-adjust {
    padding-top: 20px;
    margin-top: 20px;
}

#style-adjust-footer {
  /* padding-top: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  width: 950px;
}

#table-style{
  height: 50%;
}

.btn-style {
  width: 15em;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: rgb(204, 197, 197);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-style:hover{
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.btn-actions{
  width: 4em;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: rgb(204, 197, 197);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-actions:hover{
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  th {
    background-color: #f8f5f5;
    color: Black;
}

.pagination-style{
  padding-left: 60px; 
  padding-bottom: 40px
}

@media screen and (max-width: 995px) {
  .pagination-style{
    padding-left: 5px; 
    padding-bottom: 5px
  }
  .btn-style {
    width: 8em;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: rgb(204, 197, 197);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  .btn-actions{
    width: 4em;
    height: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: rgb(204, 197, 197);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }

  #style-adjust {
    padding-top: 20px;
    padding-left: 25px;
  }
}

.button-1 {
  width: 15em;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  }

.button-1:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

#status-active{
  color: green;
}

#status-inactive{
  color: #dc3545;
}

#status-pending{
  color: #17a2b8;
}

#status-open{
  color: red;
}

#status-pending{
  color: blue;
}

#status-resolved{
  color: green;
}

#status-closed{
  width: 90px;
  color: #e2e6ea;
}

#status-overdue{
  color: rgb(223, 132, 99);
}

#rcorners1 {
  color: blue;
}
#rcorners2 {
  color: green;
}
#rcorners3 {
  color: Red;
}

#link-actionview{
  padding-left: 15px;
}

#description{
  width: 300px;
}

#btn-success{
  float: right;
  font-size: 14px;
}

#btn-danger{
  float: right;
  margin-left: 1%;
  font-size: 14px;
}
