.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(202, 199, 199, 0.75);
  }

.modal {
    position: fixed;
    top: 35vh;
    /* left: 50%; */
    left: 10%;
    width: 80%;
    z-index: 100;
    overflow: hidden;
  }

  .loader,
.loader:before,
.loader:after {
  background: #210070;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #210070;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


/* body {
  background-color: #eee;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .loader {
  width: 150px;
  height: 150px;
}
svg {
  width: 90%;
  fill: none;
}

.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 570;
  stroke-width: 20px;
}

#one {
    stroke: #210070;
    animation: load 1.5s infinite;
  }
#two {
    stroke: #2f2e7c;
    animation: load 1.5s infinite;
    animation-delay: 0.1s;
  }
#three {
    stroke: #505697;
    animation: load 1.5s infinite;
    animation-delay: 0.2s;
  }

.point {
  animation: bounce 1s infinite ease-in-out;
}
#one1 {
    fill: #2f2e7c;
    animation-delay: 0s;
  }
#two2 {
    fill: #505697;
    animation-delay: 0.1s;
  }
#three3 {
    fill: #210070;
    animation-delay: 0.2s;
  }

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes load {
  0% {
    stroke-dashoffset: 570;
  }
  50% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 570;
    transform: rotate(360deg);
  }
} */
