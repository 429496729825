* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

.my-custom-table .fa.fa-edit {
	color: rgb(0, 119, 255);
	font-size: 16;
}

.password-div {
	position: relative;
	display: inline-block;
}

.my-custom-table .fa.fa-eye {
	position: absolute;
	right: 8px;
	top: 6px;
}

.table-wrapper {
	display: flex;
	justify-content: center;
	margin: 40px 0px;
}

.my-custom-table {
	min-width: 80%;
	border-bottom: 1px solid lightgray;
}
.my-custom-table th {
	padding: 20px 16px;
	font-size: 16px;
	text-transform: capitalize;
	color: rgb(58, 56, 56);
}
.my-custom-table td {
	padding: 20px 16px;
	font-size: 14px;
}

.my-custom-table .communication-purpose {
	text-transform: capitalize;
	color: rgb(35, 145, 248);
	font-size: 15px;
}

.my-custom-table .button-update {
	display: block;
	color: rgb(5, 155, 105);
	font-size: 14px;
	padding: 2px 4px;
	border-radius: 2px;
	background: none;
	border: 1px solid rgb(5, 155, 105);
	text-transform: capitalize;
}

.my-custom-table .action-column {
	display: flex;
	justify-content: center;
	align-items: center;
}

.my-custom-table input {
	padding: 2px 6px;
	border-radius: 2px;
	border: 1px solid gray;
}

.my-custom-table input:focus {
	border: 1px solid rgb(83, 142, 219);
	outline: 3px solid rgb(199, 236, 250);
}

.mail-validation-span {
	color: red;
	font-size: 10px;
}

.email-edit-input-tag {
	width: 225px;
	display: block;
}