.clearfix {
    clear: both;
  }
  
  .box {
    width: auto;
    color: #333;
    padding: 2px 0px;
    text-align: left;
  }
  .title-box {
    cursor: pointer;
    padding: 7px;
    background-color: #dcedff;
    border-radius: 5px;
  }
  .title {
    width: calc(100% - 20px);
  }
  .span-right{
    float: right;
  }
  .status-accept {
    padding: 8px;
    color: blue;
  }
  .status-pending {
    padding: 8px;
    color: #07bc0c;
  }
  .status-reject {
    padding: 8px;
    color: red;
  }
  .icon {
    padding-left: 5px;
    width: 20px;
    color: #666;
  }
  .icon .fa {
    font-size: 15px;
    transition: all 0.1s;
  }

  .content {
    padding: 15px;
   
  }