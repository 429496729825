#title{
    text-align: center;
}

#datetime{
    text-align: right;
}

.vl {
    border-left: 1px solid black;
    height: 400px;
  }

#page-head{
    background-color: rgb(24 225 137 / 10%);
}

#thumbnailImage{
    margin-top: 5px;
    margin-left: 5px;
    transform: scale(1.1);
}

.thumbnail:hover {
    position:relative;
    top:-15px;
    left:-5px;
    bottom: -25px;
    right: 25px;
    width:500px;
    height:300px;
    display:block;
    z-index:999;
}




/* download customer data styles */

.topimage{
    margin-left: 80px;
}

.circular{
  text-align: center;
  font-size: medium;
  font-weight: bold;
}


/* download customer data styles */