#title{
    text-align: center;
}

#datetime{
    text-align: right;
}

.vl {
    border-left: 1px solid black;
    height: 400px;
  }

#page-head1{
    background-color: rgb(239, 246, 249);
}

#userInfo{
    background-color: #f5f5f5;
    color: black;
}