/* * {
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", sans-serif;
  } */
  .font-12 {
    font-size: 12px;
  }
  .font-13 {
    font-size: 13px;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-16 {
    font-size: 18px;
  }
  .font-18 {
    font-size: 18px;
  }
  .font-20 {
    font-size: 20px;
  }

  .font-medium{
    font-size: 16px;
  }
  .width-20 {
    width: 20%;
  }
  .width-60 {
    width: 60%;
  }
  .width-100 {
    width: 100%;
  }
  .flex-row {
    display: flex;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .row-align-center {
    display: flex;
    align-items: center;
    /* height:200px; */
  }
  .pageHeading_center {
    text-align: center;
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 5px;
    /* border: 1px solid red; */
    padding: 0px;
    /* margin: 0px; */
  }
  .border {
    border: 5px solid red;
  }
  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-30 {
    margin-right: 200px;
    margin-left: 200px;
  }
  .py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pt-2{
    padding-top: 2px;
  }
  
  .py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pr-10 {
    padding-right: 10px;
  }
  .pr-5 {
    padding-right: 0px;
  }
  .pr-2 {
    margin-right: 2px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .underline {
    text-decoration: 1px solid grey underline;
    /* border-bottom: 1px solid grey; */
    /* border: 1px solid red; */
    padding-left: 7px;
    padding-right: 7px;
    width: max-content;
  }
  .dotted-underline {
    text-decoration: 1px dotted grey underline;
    /* border-bottom: 1px dotted grey; */
    padding-left: 7px;
    padding-right: 7px;
    text-align: center;
  }
  .width-max-content {
    width: max-content;
  }
  .section-header {
    padding: 4px;
    background-color: rgb(235, 231, 231);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 19px;
  }
  .font-bold {
    font-weight: 600;
  }
  .text-center {
    text-align: center;
  }
  .height {
    height: 50px;
  }
  .serial_number {
    /* width: 1%; */
    padding-left: 5px;
    padding-right: 5px;
  }
  .flex-wrap {
    flex-wrap: wrap;
    overflow-wrap: break-word;
    word-wrap: normal;
  }
  .gap-10 {
    gap: 10px;
  }

  .gap-1 {
    gap: 1px;
  }
  .gap-4 {
    gap: 4px;
  }
  .gap-30 {
    gap: 30px;
  }
  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .signature {
    height: 40px;
    width: 250px;
    border-bottom: 1px solid black;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .dotted-signature {
    height: 50px;
    width: 250px;
    border-bottom: 1px dotted black;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .space-between {
    justify-content: space-between;
  }
  .date-input {
    /* font-style: italic; */
    text-align: center;
    width: 150px;
    /* margin-right: 5px; */
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 30px;
    /* text-decoration: 1px solid black underline; */
    border-bottom: 1px solid #000;
    font-size: 12px;
    padding: 0px;
    /* height: max-content; */
  }
  .date_div {
    width: 150px;
    margin-right: 30px;
  }
  .dotted-input {
    width: 150px;
    border-bottom: 1px dotted black;
    /* text-decoration: 1px dotted black underline; */
    margin-right: 5px;
    margin-left: 5px;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
  }
  
  .date {
    margin-bottom: 5px;
  }
  .checkbox {
    height: 14px;
    width: 14px;
    margin-right: 15px;
    border: 0.5px solid black;
    margin-left: 20px;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-3 {
    margin-bottom: 3px;
  }
  .mb-4 {
    margin-bottom: 4px;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  .img_div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo {
    /* align-self: center; */
    height: 35px;
    width: 13%;
    position: absolute;
  }
  
  .person_img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    position: absolute;
    right: 00px;
    border: 1px solid black;
    top: 0px;
  }
  
  .personal_details {
    position: relative;
  }