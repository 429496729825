#status-open{
    width: 90px;
    color: red;
  }
  
  #status-pending{
    width: 90px;
    color: blue;
  }
  
  #status-resolved{
    width: 90px;
    color: green;
  }
  
  #status-closed{
    width: 90px;
    color: #e2e6ea;
  }

  #rcorners1 {
    color: blue;
    text-align: center;
  }
  #rcorners2 {
    color: green; 
    text-align: center;
  }
  #rcorners3 {
    color: Red;
    text-align: center;
  }

  #link-actionview{
    padding-left: 15px;
  }