#profile-all-items{
    margin-top: 50px;
}

.container-top{
    margin-top: 10px;
    margin-left: 100px;
    margin-right: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#profile-box-item{
    margin-top: 45px;
    margin-left: 45px;
    width: 196px;
    height: 46px;
    border: inset
}

.profile-item-style{
    color: black;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 14px;
    text-align: center;
}

#profile-data-icon{
    padding-right: 10px;
    font-size: 14px;
}