.app {
    /* background-color: #252d4a; */
    font-family: "Verdana", cursive, sans-serif;
    margin-top: 100px;
    margin-bottom: 20px;
    margin-left: 315px;
    text-align: center;
    width: 800px;
    min-height: 200px;
    height: min-content;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-evenly;
  }
  
  .score-section {
    /* display: flex; */
    font-size: 30px;
    /* align-items: center; */
  }
  
  /* QUESTION/TIMER/LEFT SECTION */

  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 25px;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  

  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .button {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    /* color: #ffffff;
    background-color: #252d4a; */
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #474d53;
    cursor: pointer;
  }
  
  .correct {
    background-color: #b4a862;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  .button:hover {
    background-color: #bfc0b3;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button svg {
    margin-right: 5px;
  }
  



















/* #title{
    text-align: center;
}

#datetime{
    text-align: right;
}

.vl {
    border-left: 1px solid black;
    height: 400px;
  }

#page-head{
    background-color: rgb(24 225 137 / 10%);
} */