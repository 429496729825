*{
    font-family: century-gothic, sans-serif;
    font-size: 13px;
    font-style: normal;
}

.required:after {
    content: "*";
    color: #d00;
    font-size: 16.8px;
    font-weight: bold;
    position: relative;
    margin-left: 5px;
}

.required:after {
    content: "*";
    color: #d00;
    font-size: 16.8px;
    font-weight: bold;
    position: relative;
    margin-left: 5px;
}

#adjust-top{
    margin-top: 90px;
}